body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  height: 100%;
  background:#f4f4f4;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header, .footer {
  background-color: white;
  color: var(--main-color);
  padding: 15px 20px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header {
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 50px;
  margin-right: 10px;
}
a {
  color: var(--main-color);;
  font-weight: 700;
  text-decoration: none;
  font-size: 24px;
}

.footer {
  bottom: 0;
  text-align: center;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
}

.main-content {
  flex: 1;
  margin-top: 60px; /* Adjust based on the height of the header */
  margin-bottom: 40px; /* Adjust based on the height of the footer */
}

/* Styles for when .main-content has the loading class */
.main-content.loading {
  filter: blur(3px); /* This will blur the contents, you can adjust the value or remove this line if you don't want blur */
  overflow: hidden; /* This will hide the scrollbar while the content is loading */
}
.main-content.loading #pdf-container{
  display: none !important;
}
.main-content.loading .controls{
  display: none !important;
}

.main-content.loading::before {
  content: ''; /* This is required for the pseudo-element to display */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent overlay */
  z-index: 10; /* Ensures the overlay appears on top of the content */

  /* Optional: Centered spinner or message */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Optional: Styling for a simple loading message or spinner */
.main-content.loading::before::after {
  content: 'Loading...';
  color: white;
  font-size: 1.5em;
  font-weight: bold;
}
button[disabled]{
  opacity: 0.25 !important;
}