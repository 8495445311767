@keyframes fadeOut {
    from {
        background-color:var(--main-color);;  /* Change to your desired solid color */
    }
    to {
        background-color: rgba(255, 255, 255, 0);  /* Transparent */
    }
}

.fade-out-animation {
    @media screen and (max-width: 767px) {
        animation-name: fadeOut;
        animation-duration: 2s;
        animation-fill-mode: forwards;  /* Ensures the ending state remains after the animation completes */
    }
}


.flipbook {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/* Add more styles as needed */
button i.fa {
    margin-right: 8px;
}

button {
    background: var(--main-color);;
    color: white !important;
    font-weight: 500;
    box-shadow: none;
    padding: 15px 30px;
    width: 48px;
    text-transform: uppercase;
    margin-top: 10px;
    border: none;
    height: calc(100vh - 20px);
}

.button-wrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-grow: 1;
}

.button .span {
    @media (max-width: 767px): {
    display: none;
}
}

.controls .action {
    position: fixed;
    top: 0;
    opacity: 0.5;
    @media(max-width: 767px) {
    opacity: 1;
    }
}

.controls .action:hover {
    opacity: 1;
}

.controls .previous {
    left: 0;

}

.controls .next {
    right: 0;
}